import React, { useState } from 'react';
import "./Pagination.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

const host = 'https://api.scalewithbuddha.com/api'

const Pagination = ({ data, itemsPerPage }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false); // Loader state
    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handleChangePage = (pageNum) => {
        setCurrentPage(pageNum);
    };

    const handleDelete = async (itemId) => {
        // Show confirmation dialog
        const isConfirmed = window.confirm('Are you sure you want to delete this item?');
        
        if (!isConfirmed) {
            return; // Exit if user cancels
        }
    
        setLoading(true); // Show loader
        try {
            // Retrieve token from local storage
            const token = localStorage.getItem('user'); // Adjust key as needed
    
            const response = await fetch(`${host}/schedule?id=${itemId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: token,
                },
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            toast.success('Item deleted successfully!'); // Success message
            // Optionally refresh the data or update the state to remove the item from the UI
            window.location.reload();
        } catch (error) {
            toast.error('Error deleting item.'); // Error message
        } finally {
            setLoading(false); // Hide loader
        }
    };
    

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = data.slice(startIndex, startIndex + itemsPerPage);

    if (currentItems.length === 0 || data.length === 0) {
        return (
            <div className="no-schedules-message">
                No schedules configured yet
            </div>
        );
    }

    return (
        <div className="pagination-container">
            <h2>Your Schedules</h2>
            <ul className="items-list">
                {currentItems.map((item) => (
                    <li key={item._id} className="item">
                        <div className="card">
                            <div className="card-content">
                                <h3>{item.projectName}</h3>
                                <p><strong>Cluster:</strong> {item.clusterName}</p>
                                <p><strong>Scale Up:</strong> {item.scaleUpTier} @ {item.scaleUpTime}</p>
                                <p><strong>Scale Down:</strong> {item.scaleDownTier} @ {item.scaleDownTime}</p>
                                <p><strong>Timezone:</strong> {item.timezone} </p>
                                <p><strong>Frequency:</strong> {item.repeatFrequency} </p>
                            </div>
                            <div className="action-buttons">
                                <label onClick={() => handleDelete(item._id)} className="delete-label">
                                    <FontAwesomeIcon icon={faTrash} />
                                </label>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Only show pagination controls if there are more items than the items per page */}
            {totalPages > 1 && (
                <div className="pagination-controls">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={currentPage === index + 1 ? 'active' : ''}
                            onClick={() => handleChangePage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}

            <ToastContainer /> {/* Toaster container */}
            {loading && <div className="loader">Loading...</div>} {/* Loader UI */}
        </div>
    );
};

export default Pagination;
