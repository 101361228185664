import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination'; // Assume you have a Pagination component
import Modal from './Modal'; // Assume you have a Modal component for pop-up
import Header from './Header'; // Import the Header component
import "./Dashboard.css";
import AddScheduleForm from './AddScheduleForm';
import Footer from './Footer';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
import { Analytics } from "@vercel/analytics/react"

const host = 'https://api.scalewithbuddha.com/api'

const Dashboard = ({ userName }) => {
    const [schedules, setSchedules] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    let token;

    const closeModal = () => {
        setShowModal(false);
        fetchSchedules(token);
    };

    const handleLogout = () => {
        localStorage.removeItem('user'); // Clear the user token
        window.location.href = '/'; // Redirect to login page
    };

    // Fetch schedules when the component mounts
    useEffect(() => {
        const fetchToken = () => {
            const token = localStorage.getItem('user');
            if (!token) {
                alert('Invalid login');
                window.location.href = '/'; // Redirect to login page
            }
            return token;
        };

        token = fetchToken();
        if (token) {
            fetchSchedules(token);
        }
    }, []);

    const fetchSchedules = async (token) => {
        try {
            const response = await axios.get(host + '/schedules', {
                headers: {
                    Authorization: token,
                },
            });

            if (response.data && response.status > 199 && response.status < 300) {
                setSchedules(response.data.schedules);
            } else {
                setError('Error fetching schedules');
            }
        } catch (err) {
            alert(err.message);
            setError('Error fetching schedules');
        }
    };

    return (
        <div className="dashboard-container">
            <Header onLogout={handleLogout} /> {/* Include Header here */}
            <div className="schedules-list">
                {error ? (
                    <p>{error}</p>
                ) : schedules.length === 0 ? ( // Check if there are no schedules
                    <p className="no-schedules">No schedules configured yet</p>
                ) : (
                    <Pagination data={schedules} itemsPerPage={10} />
                )}
            </div>
            <button className="add-schedule-btn" onClick={() => setShowModal(true)}>
                <i className="fas fa-plus"></i> {/* Plus icon */}
            </button>
            <Footer />
            {showModal && (
                <Modal onClose={() => setShowModal(false)}>
                    <AddScheduleForm closeModal={closeModal} />
                </Modal>
            )}
            <Analytics />
        </div>
    );
};

export default Dashboard;
