// Header.js
import React from 'react';
import logo from './logo.png'; // Adjust the path to your logo image
import './Header.css'; // Import the Header styles

const Header = ({ onLogout }) => {
    return (
        <header className="header">
            <img src={logo} alt="Brand Logo" className="brand-logo" />
            <h1 className="header-title">Dashboard</h1> {/* Add the title here */}
            <nav className="nav">
                <button className="logout-btn" onClick={onLogout}>
                    Logout
                </button>
            </nav>
        </header>
    );
};

export default Header;