import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Calendar } from 'react-multi-date-picker';
import "react-multi-date-picker/styles/colors/teal.css";
import "./AddScheduleForm.css";
import Select from "react-select";

const host = 'https://api.scalewithbuddha.com/api'

const AddScheduleForm = ({closeModal}) => {
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [showSubscribeError, setShowSubscribeError] = useState(false);
    const [accessKey, setAccessKey] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [clusters, setClusters] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState('');
    const [timezones, setTimezones] = useState([]);
    const [tiers, setTiers] = useState([]);
    const [scheduleDetails, setScheduleDetails] = useState({
        timezone: '',
        scaleUpTier: '',
        scaleUpTime: '',
        scaleDownTier: '',
        scaleDownTime: '',
        repeatFrequency: 'Daily',
        customDates: [],
        atlasAccessKey: accessKey,
        atlasAccessToken: accessToken,
        clusterId: selectedCluster.clusterId,
        clusterName: selectedCluster.name,
        projectId: selectedCluster.groupId
    });
    const [apiError, setApiError] = useState('');
    const [apiSuccess, setApiSuccess] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [token, setToken] = useState('');

    // New state variables for loading states
    const [isLoadingClusters, setIsLoadingClusters] = useState(false);
    const [isSubmittingSchedule, setIsSubmittingSchedule] = useState(false);

    const fetchToken = () => {
        const userToken = localStorage.getItem('user');
        if (!userToken) {
            alert('Invalid login');
            window.location.href = '/login';
        } else {
            setToken(userToken);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const timezoneResponse = await axios.get(host + '/timezones');
                const tierResponse = await axios.get(host + '/tiers');
                setTimezones(timezoneResponse.data.timezones);
                setTiers(tierResponse.data.tiers);
            } catch (err) {
                console.error('Error fetching data', err);
            }
        }
        fetchToken();
        fetchData();
    }, []);

    const handleGetClusters = async () => {
        try {
            setApiError('');
            setIsLoadingClusters(true); // Set loading to true
            if (!token) {
                fetchToken();
            }
            const response = await axios.get(`${host}/clusters`, {
                headers: {
                    'Access-Key': accessKey,
                    'Access-Token': accessToken,
                    'Authorization': token
                },
            });
            if (response.status === 200) {
                setClusters(response.data.clusters);
                setApiSuccess(true);
                setIsLoadingClusters(false); // Set loading to false
            }
        } catch (err) {
            console.error('Error fetching clusters', err);
            setApiError('Failed to fetch clusters. Please check your credentials.');
            setIsLoadingClusters(false); // Set loading to false in case of error
        }
    };

    const handleScheduleChange = (e) => {
        const { name, value } = e.target;
        setScheduleDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleRepeatFrequencyChange = (e) => {
        const { value } = e.target;
        setScheduleDetails((prevDetails) => ({
            ...prevDetails,
            repeatFrequency: value,
        }));
        setShowCalendar(value === 'Custom');
    };

    const handleCustomDatesChange = (dates) => {
        setScheduleDetails((prevDetails) => ({
            ...prevDetails,
            customDates: dates.map(d => d.format("YYYY-MM-DD")),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitSuccess(false);
        setSubmitError('');
        setIsSubmittingSchedule(true); // Set loading state to true when saving
        try {
            if (!token) {
                fetchToken();
            }
            const cluster = selectedCluster.split("|");
            const obj = {
                ...scheduleDetails,
                atlasAccessKey: accessKey,
                atlasAccessToken: accessToken,
                clusterName: cluster[3],
                clusterId: cluster[2],
                projectId: cluster[0],
                projectName: cluster[1]
            }
            await axios.post(host + '/schedules', obj, {
                headers: {
                    'Authorization': token
                }
            });
            setSubmitSuccess(true);
            setIsSubmittingSchedule(false); // Set loading state to false after successful submission
            window.location.reload();
            closeModal();
        } catch (err) {
            let m = 'Failed to save the schedule. Please try again later.';
            if (err.response && err.response.data && err.response.data.message) {
                m = err.response.data.message;
            }
            if (m.toLowerCase().includes("subscribe")) {
                setShowSubscribeError(true);
            } else {
                setShowSubscribeError(false);
            }
            setSubmitError(m);
            setIsSubmittingSchedule(false); // Set loading state to false on error
        }
    };

    return (
        <form onSubmit={handleSubmit} className="schedule-form">
            <div className="form-group">
                <label>MongoDB Atlas Access Key:</label>
                <input
                    type="text"
                    value={accessKey}
                    onChange={(e) => setAccessKey(e.target.value)}
                    disabled={apiSuccess || isLoadingClusters} // Disable when clusters are loaded or loading
                    required
                />
            </div>

            <div className="form-group">
                <label>MongoDB Atlas Access Token:</label>
                <input
                    type="password"
                    value={accessToken}
                    onChange={(e) => setAccessToken(e.target.value)}
                    disabled={apiSuccess || isLoadingClusters} // Disable when clusters are loaded or loading
                    required
                />
            </div>

            <button type="button" onClick={handleGetClusters} className="get-clusters-btn" disabled={isLoadingClusters}>
                {isLoadingClusters ? 'Loading Clusters...' : 'Get My Clusters'}
            </button>

            {apiError && <p className="error-message">{apiError}</p>}

            {apiSuccess && (
                <>
                <p></p>
                    <div className="form-group">
                        <label>Select Cluster:</label>
                        <select
                            value={selectedCluster}
                            onChange={(e) => setSelectedCluster(e.target.value)}
                            required
                        >
                            {clusters.map((cluster) => (
                                <option key={cluster.clusterId} value={`${cluster.groupId}|${cluster.groupName}|${cluster.clusterId}|${cluster.name}`}>
                                    {cluster.groupName}.{cluster.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Timezone:</label>
                        <Select
                            name="timezone"
                            value={timezones.find((tz) => tz === scheduleDetails.timezone) ? { value: scheduleDetails.timezone, label: scheduleDetails.timezone } : null}
                            onChange={(selectedOption) =>
                                handleScheduleChange({
                                    target: {
                                        name: 'timezone',
                                        value: selectedOption.value,
                                    }
                                })
                            }
                            options={timezones.map((tz) => ({
                                value: tz,
                                label: tz
                            }))}
                            isSearchable
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Scale Up Tier:</label>
                        <select
                            name="scaleUpTier"
                            value={scheduleDetails.scaleUpTier}
                            onChange={handleScheduleChange}
                            required
                        >
                            {tiers.map((tier) => (
                                <option key={tier} value={tier}>
                                    {tier}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Scale Up Time:</label>
                        <input
                            type="time"
                            name="scaleUpTime"
                            value={scheduleDetails.scaleUpTime}
                            onChange={handleScheduleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Scale Down Tier:</label>
                        <select
                            name="scaleDownTier"
                            value={scheduleDetails.scaleDownTier}
                            onChange={handleScheduleChange}
                            required
                        >
                            {tiers.map((tier) => (
                                <option key={tier} value={tier}>
                                    {tier}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Scale Down Time:</label>
                        <input
                            type="time"
                            name="scaleDownTime"
                            value={scheduleDetails.scaleDownTime}
                            onChange={handleScheduleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Repeat Frequency:</label>
                        <select
                            name="repeatFrequency"
                            value={scheduleDetails.repeatFrequency}
                            onChange={handleRepeatFrequencyChange}
                            required
                        >
                            <option value="Daily">Daily</option>
                            <option value="Weekend">Only Weekends</option>
                            <option value="Weekday">Only Weekdays (Mon-Fri)</option>
                            <option value="Custom">Custom</option>
                        </select>
                    </div>

                    {showCalendar && (
                        <div className="form-group">
                            <label>Select Custom Dates:</label>
                            <Calendar
                                multiple
                                onChange={handleCustomDatesChange}
                                className="teal"
                                format='YYYY-MM-DD'
                            />
                        </div>
                    )}

                    <button type="submit" className="submit-btn" disabled={isSubmittingSchedule}>
                        {isSubmittingSchedule ? 'Saving...' : 'Save Schedule'}
                    </button>

                    {submitSuccess && <p className="success-message">Schedule saved successfully!</p>}
                    {submitError && <p className="error-message">{submitError}{showSubscribeError && <a href="https://subscribe.scalewithbuddha.com/"> Click here to subscribe</a>}</p>}
                </>
            )}
        </form>
    );
};

export default AddScheduleForm;
