import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Optional, if you want separate styling for the Login component
import logo from '../assets/logo-2.png'; // Import the logo
import Footer from './Footer';
import { Analytics } from "@vercel/analytics/react"

const host = 'https://api.scalewithbuddha.com/api'

const Login = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  // Request OTP function
  const requestOtp = async () => {
    setIsLoading(true); // Set loading to true before making the request
    try {
      const response = await axios.get(host + '/otp?email=' + email, {
        timeout: 10000,
      }); 
      if (response.status === 200) {
        setIsOtpRequested(true);
      }
    } catch (err) {
      setError('Error requesting OTP. Please try again.');
    } finally {
      setIsLoading(false); // Set loading to false after request is complete
    }
  };

  // Submit OTP function
  const submitOtp = async () => {
    setIsLoading(true); // Set loading to true before making the request
    try {
      setError('');
      const response = await axios.post(host + '/otp', { email, otp }, {
        timeout: 10000,
      }); 
      if (response.status === 200) {
        localStorage.setItem('user', response.data.token);
        navigate('/dashboard');
      }
    } catch (err) {
      setError('Invalid OTP. Please try again.');
    } finally {
      setIsLoading(false); // Set loading to false after request is complete
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        {/* Placeholder for Logo */}
        <div className="logo-placeholder">
          <img src={logo} alt="Logo" />
        </div>

        <h2>Scale with Buddha</h2>

        <div className="form-group">
          <label htmlFor="email">Enter your email address</label>
          <input
            type="email"
            id="email"
            placeholder="you@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
        </div>

        {!isOtpRequested ? (
          <>
            {isLoading ? <p>Loading...</p> : <button className="request-button" onClick={requestOtp}>Request OTP</button>}
          </>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="password"
                id="otp"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="input-field"
              />
            </div>
            {isLoading ? <p>Loading...</p> : <button className="submit-button" onClick={submitOtp}>Submit</button>}
          </>
        )}

        {error && <p className="error-message">{error}</p>}
      </div>
      <Footer />
      <Analytics />
    </div>
  );
};

export default Login;